.chat-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 700px;
  min-width: 700px;
  min-height: 470px;
}

.chat-header {
  background-color: #147AAE;
  color: #fff;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid gray;
}

.chat-messages {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

.chat-messages .message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  font-size: 16px;
  /* max-width: 80%;
  overflow: auto; */
}

.chat-messages .message.user {
  background-color: #147AAE;
  color: #fff;
  align-self: flex-end;
  width: fit-content;
  font-weight: 400;
  /* height: max-content; */
}

.chat-messages .message.bot {
  background-color: rgb(131, 126, 126);
  color: white;
  align-self: flex-start;
  font-weight: bold;
  width: fit-content;
}