.trade_fair_container {
  width: 100%;
  /* height: 100vh; */
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  /* overflow: hidden; */
}

.text_box {
  border-bottom: 2px solid gray;
  padding: 5px 0px;
  display: flex;
  justify-content: center;
  border: 1px solid red
    /* align-items: center; */
    /* flex-direction: column; */
}

.text_title {
  font-family: monospace;
  font-size: 19px;
  font-weight: bold;
  margin: 0px;
  align-self: center;
}

.text {
  /* font-family: 'Times New Roman', Times, serif; */
  font-size: 15px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid gainsboro;
  padding: 0px 15px;
}

.text-content {
  width: 100%;
  border-left: 1px solid gainsboro;
}

/* .text-content :first-child {
  margin-top: 0px;
} */

.text-content :last-child {
  margin-bottom: 0px;
}

.title-content {
  min-width: 200px;
}

/* .title-content :first-child {
  margin-top: 0px;
} */

.title-content :last-child {
  margin-bottom: 0px;
}

.title {
  font-size: 15px;
  color: rgb(36, 147, 242);
  font-weight: 500;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid gainsboro;
  padding-left: 15px;
}

.title :last-child {
  margin-bottom: 0px;
}

/* .container {
  display: flex;
  justify-content: space-between;
}

.left,
.right {
  flex: 1;
  padding: 10px;
}

.tag {
  font-weight: bold;
  margin-bottom: 5px;
}

.content {
  margin-bottom: 10px;
} */